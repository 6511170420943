<template>
  <div class="col-sm-12">
    <div class="card">
      <v-card class="card-header">
        <!-- Tabs -->
        <v-tabs v-model="activeTab" align-tabs="start" color="deep-purple-accent-4">
          <v-tab class="text-table-primary">Carga Individual</v-tab>
          <v-tab class="text-table-primary">Carga Múltiple con Excel</v-tab>
        </v-tabs>

        <!-- Tab Contents -->
        <v-tabs-items v-model="activeTab">
          <!-- Primera pestaña: CARD 1 PROCESAMIENTO INDIVIDUAL -->
          <v-tab-item>
            <template v-if="activeTab === 0">
              <div class="card">
                <div class="card-header pb-0">
                  <div class="row">
                    <div class="col-lg-4 col-12 mb-3">
                      <label class="text-table-primary">Ingresa un Codigo de Barras:</label>
                      <input class="form-control" type="text" placeholder="Producto" v-model="codigoProducto" />
                    </div>
                    <div class="col-lg-4 col-12 mb-3">
                      <button class="btn btn-primary mb-3" style="margin-left:auto; margin: 20px 25px"
                        :disabled="isLoading" @click="searchProduct">
                        Procesar
                        <i class="icofont icofont-search"></i>
                      </button>
                    </div>
                    <div class="col-lg-4 col-12 mb-3">
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="col-lg-4 col-12 mb-3">
                    <div v-if="imagenURL">
                      <img :src="imagenURL" alt="Imagen del Producto" class="img-fluid" />
                      <div class="mt-3">
                        <p class="text-table-primary"><strong>Código del Producto:</strong> {{ codigoProducto }}</p>
                      </div>
                    </div>
                    <div v-else>
                      <p></p>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </v-tab-item>

          <!-- Segunda pestaña: CARD 2 PROCESAMIENTO POR EXCEL -->
          <v-tab-item>
            <template v-if="activeTab === 1">
              <div class="card">
                <div class="card-header pb-0">
                  <div class="row">
                    <div class="col-lg-4 col-12 mb-3">
                      <label class="form-label text-table-primary">Cargar Excel:</label>
                      <div class="input-group">
                        <input type="file" @change="handleFileChange" accept=".xlsx, .xls" class="form-control"
                          id="inputGroupFile" />
                      </div>
                      <br>
                      <label class="form-label text-table-primary">¡Subir un Maximo de 100 Productos!</label>
                    </div>
                    <div class="col-lg-4 col-12 mb-2"> </div>
                    <div class="col-lg-4 col-12 mb-3">
                      <label class="form-label text-table-primary">Plantilla Excel:</label>
                      <div>
                        <button class="btn btn-success mb-3" style="margin-right: 10px;" :disabled="isLoading"
                          @click="downloadExcelBase">
                          Descargar Plantilla
                          <i class="icofont icofont-download"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                </div>
              </div>
            </template>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
    <Loader v-if="isLoading" :textoLoader="textoLoader" :tituloLoader="tituloLoader" />
  </div>
</template>

<script>
import axios from 'axios';
import * as XLSX from 'xlsx';
import Loader from "@/components/Loader.vue";
import { getmultimediaContentGS1, uploadProduct, getProductFotoRFCHasura } from "@/store/apibase";
import { io } from "socket.io-client";
import { toast } from "vue3-toastify";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  components: {
    Loader
  },
  data() {
    return {
      codigoProducto: '',
      textoLoader:
        "Por favor, permanezca en esta pestaña hasta que se complete la carga.",
      tituloLoader: "Cargando imágenes",
      imagenBlob: [],
      invalidFiles: [],
      productosSinFoto: [],
      resultados: [],
      activeTab: 0,
      uploadedFile: null,
      isDragging: false,
      uploading: false,
      isLoading: false,
      socketActual: null,
    };
  },
  mounted() {
    this.revisarConexionExistente();

  },
  computed: {
    ...mapState("autenticacion", ["usuarioActual"]),
    ...mapState("socket", ["socket"]),
    canUpload() {
      return this.images.length > 0 && !this.uploading;
    },
  },
  methods: {
    ...mapMutations("socket", ["setSocket"]),
    ...mapActions("destinatarios", ["obtenerDestinatariosImagenes"]),
    revisarConexionExistente() {
      if (!this.socket) {
        this.conectarSocket();
      } else {
        this.socketActual = this.socket;
      }
    },
    conectarSocket() {
      // this.socketActual = io("http://localhost:3000", {
      //   withCredentials: true,
      //   extraHeaders: {
      //     "my-custom-header": "abcd",
      //   },
      // });

      this.socketActual = io("https://despliegueimagenessk.superkompras.com.mx", {
        withCredentials: true,
        extraHeaders: {
          "my-custom-header": "abcd",
        },
      });
      this.setSocket(this.socketActual);
      this.socketActual.on("connect", () => {
        console.log("Conectado al servidor");
        console.log(this.socketActual.id);
        this.socketActual.emit("mensaje", "Hola servidor!");
      });

      this.socketActual.on("image-upload-state", (data) => {
        console.log(data.message);

        switch (data.message) {
          case "imagenes-cargadas":
            toast.success(
              " ¡Proceso en segundo plano! Puede cerrar la pestaña si lo desea ",
              {
                hideProgressBar: true,
                autoClose: 10000,
                theme: "colored",
              }
            );
            this.tituloLoader = "Procesando imágenes";
            this.textoLoader =
              "Esto puede tardar unos minutos. Recibirá un correo cuando el proceso haya finalizado.";
            break;
          case "imagenes-procesadas":
            this.tituloLoader = "Guardando imágenes";
            this.textoLoader =
              "Esto puede tardar unos minutos. Recibirá un correo cuando el proceso haya finalizado.";
            break;
        }
      });
    },
    //SUBIR INDIVIDUAL
    async searchProduct() {
      this.codigoProducto = this.codigoProducto.trim();

      // Verificar si el código no está vacío
      if (!this.codigoProducto) {
        this.$swal({
          icon: "warning",
          title: `Ingrese un código Por Favor`,
        });
        return;
      }

      // Completar el código con ceros si tiene menos de 14 dígitos
      if (this.codigoProducto.length < 14) {
        this.codigoProducto = this.codigoProducto.padStart(14, '0');
      }
      this.isLoading = true;
      try {
        const response = await axios.post(getmultimediaContentGS1, {
          GTIN: [this.codigoProducto]
        }, {
          responseType: 'blob'
        });

        // Guardar el blob y crear un archivo desde el blob
        this.imagenBlob = response.data;

        const gtinSinCeros = this.codigoProducto.replace(/^0+/, '');

        const file = new File([this.imagenBlob], `${gtinSinCeros}.jpg`, {
          type: 'image/jpeg',
        });

        this.imagenBlob = [file];

        // Crear la URL para mostrar la imagen
        this.imagenURL = window.URL.createObjectURL(this.imagenBlob[0]);

        // Llamar a la función uploadImages para procesar la imagen
        await this.uploadImages();  // Aquí es donde se llama a uploadImages

      } catch (error) {
        this.$swal({
          icon: "warning",
          title: `GTIN ${this.codigoProducto} no disponible en Infocode`,
        });
        this.imagenURL = null;
        this.imagenBlob = null;
      } finally {
        this.isLoading = false;
      }
    },

    async uploadImages() {
      this.tituloLoader = "Cargando imágenes";
      this.textoLoader = "Cargando imágenes. Por favor, permanezca en esta pestaña hasta que se complete la carga.";
      this.uploading = true;
      this.isLoading = true;

      const destinatarios = await this.obtenerDestinatariosImagenes();
      const destinatarioEncontrado = destinatarios.find((destinatario) => destinatario === this.usuarioActual?.email);

      if (!destinatarioEncontrado) {
        destinatarios.push(this.usuarioActual?.email);
      }

      const formData = new FormData();
      const uid = this.usuarioActual?.uid;
      if (!uid) {
        console.error("UID no disponible");
        this.uploading = false;
        this.isLoading = false;
        return;
      }

      console.log("UID:", uid);
      formData.append("uid", uid);

      this.imagenBlob.forEach((image) => {
        formData.append("files", image);
      });

      formData.append("socketId", this.socketActual.id);
      formData.append("email", JSON.stringify(destinatarios));

      const invalidFilesData = this.invalidFiles.map((image) => ({
        name: image.name,
        reason: image.reason,
      }));
      formData.append("invalidFiles", JSON.stringify(invalidFilesData));

      try {
        const response = await axios.post(uploadProduct, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        this.imagenBlob = this.imagenBlob.map((image) => ({
          ...image,
          uploaded: true,
        }));

        console.log("EXITO", response.data);
      } catch (error) {
        console.error("ERROR AL SUBIR IMÁGENES", error);
      } finally {
        this.uploading = false;
        this.isLoading = false;
      }
    },


    //subir por excel
    //DESCARGAR EXCEL
    async downloadExcelBase() {
      try {
        this.isLoading = true;
        this.tituloLoader = "GENERANDO ARCHIVO EXCEL";
        this.textoLoader =
          "Por favor espere se están generado el archivo";
        const data = [
          { cb: '' },
        ];

        const worksheet = XLSX.utils.json_to_sheet(data);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Datos');

        XLSX.writeFile(workbook, 'plantilla-carga-GS1.xlsx');

        this.$swal({
          icon: "success",
          title: `Archivo Excel Generado Exitosamente`,
        });
      } catch (error) {
        this.$swal({
          icon: "warning",
          title: `Error al Descargar Archivo`,
        });
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.uploadedFile = file;
        this.processExcelUpload();
      }
    },
    async processExcelUpload() {
      try {
        if (!this.uploadedFile) {
          this.$swal({
            icon: "warning",
            title: "Archivo no seleccionado",
            text: "Por favor, selecciona un archivo Excel que contenga el campo 'cb' antes de continuar.",
            confirmButtonText: "Aceptar",
          });
          return;
        }

        // Leer el archivo Excel
        const data = await this.readExcelFile(this.uploadedFile);
        console.log("Datos leídos del archivo:", data);

        // Verificar si el campo 'cb' existe
        if (!data[0] || !data[0].hasOwnProperty('cb')) {
          this.$swal({
            icon: "error",
            title: "Campo 'cb' no encontrado",
            text: "Por favor, asegúrate de que el archivo contiene una columna llamada 'cb'.",
            confirmButtonText: "Aceptar",
          });
          return;
        }

        // Convertir los valores de 'cb' a texto y asegurarse de que tengan 14 dígitos
        this.productosSinFoto = data.map((row) => {
          const cbValue = String(row.cb); // Convertir a string
          return cbValue.padStart(14, '0'); // Asegurar 14 dígitos
        });
        console.log("Códigos de barra extraídos:", this.productosSinFoto);

        // Almacenar todas las imágenes
        this.imagenes = [];  // Array donde se almacenarán las imágenes obtenidas

        this.resultados = [];
        for (const cb of this.productosSinFoto) {
          await this.procesarGs1(cb);
        }

        // Llamar a uploadImages solo después de que todas las imágenes se hayan almacenado
        await this.uploadImages2();

        // Generar el archivo Excel con los resultados
        this.generarExcelResultados();

        this.$swal({
          icon: "success",
          title: "Proceso completado",
          text: "Se ha procesado todas las imágenes y generado el archivo Excel.",
          confirmButtonText: "Aceptar",
        });

      } catch (error) {
        console.error("ERROR:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Ocurrió un error al procesar las imágenes o generar el archivo Excel.",
          confirmButtonText: "Aceptar",
        });
      }
    },

    async readExcelFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          let jsonData = XLSX.utils.sheet_to_json(worksheet);

          // Limitar a solo 100 registros
          if (jsonData.length > 100) {
            jsonData = jsonData.slice(0, 100);
            this.$swal({
              title: "Límite alcanzado",
              text: "Solo se permiten procesar 100 registros.",
              icon: "warning",
              confirmButtonText: "Aceptar"
            });

            this.isLoading = false;
            return;
          }
          resolve(jsonData);
        };
        reader.onerror = (error) => reject(error);
        reader.readAsArrayBuffer(file);
      });
    },
    async procesarGs1(cb) {
      try {
        this.isLoading = true;
        const response = await axios.post(getmultimediaContentGS1, {
          GTIN: [cb]
        }, {
          responseType: 'blob'
        });

        // Almacenar el blob de la imagen en el array 'imagenes'
        this.imagenBlob = response.data;

        const gtinSinCeros = cb.replace(/^0+/, '');  // Remover los ceros al inicio

        const file = new File([this.imagenBlob], `${gtinSinCeros}.jpg`, {
          type: 'image/jpeg',
        });

        // Agregar la imagen al array
        this.imagenes.push(file);

        this.resultados.push({ cb: gtinSinCeros, foto: 'SI' });

      } catch (error) {
        console.error(`Error procesando CB ${cb}`, error);

        const gtinSinCeros = cb.replace(/^0+/, '');
        this.resultados.push({ cb: gtinSinCeros, foto: 'NO' });

        this.imagenURL = null;
        this.imagenBlob = null;
      } finally {
        this.isLoading = false;
      }
    },

    async uploadImages2() {
      // Aquí procesamos todas las imágenes que se han almacenado
      this.tituloLoader = "Cargando imágenes";
      this.textoLoader = "Cargando imágenes. Por favor, permanezca en esta pestaña hasta que se complete la carga.";
      this.uploading = true;
      this.isLoading = true;

      const destinatarios = await this.obtenerDestinatariosImagenes();
      console.log(destinatarios);

      const destinatarioEncontrado = destinatarios.find((destinatario) => {
        return destinatario == this.usuarioActual?.email;
      });

      if (!destinatarioEncontrado) {
        destinatarios.push(this.usuarioActual?.email);
      }

      console.log(destinatarios);

      const formData = new FormData();

      const uid = this.usuarioActual?.uid;
      if (uid) {
        formData.append("uid", uid);
      } else {
        console.error("No se encontró el userId (uid)");
        this.uploading = false;
        this.isLoading = false;
        return;
      }

      this.imagenes.forEach((image) => {
        formData.append("files", image);
      });

      formData.append("socketId", this.socketActual.id);
      formData.append("email", destinatarios);

      const invalidFilesData = this.invalidFiles.map((image) => {
        return {
          name: image.name,
          reason: image.reason,
        };
      });

      formData.append("invalidFiles", JSON.stringify(invalidFilesData));

      try {
        const response = await axios.post(uploadProduct, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        this.imagenes = this.imagenes.map((image) => ({
          ...image,
          uploaded: true,
        }));

        console.log("EXITO", response.data);
      } catch (error) {
        console.error("ERROR AL SUBIR IMAGENES", error);
      } finally {
        this.uploading = false;
        this.isLoading = false;
      }
    },

    generarExcelResultados() {
      const ws = XLSX.utils.json_to_sheet(this.resultados);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Resultados");

      XLSX.writeFile(wb, "resultados_gs1.xlsx");
    },
  }
}
</script>
