const Url = //'http://localhost:3000';
//'https://despliegueimagenessk.superkompras.com.mx';
'https://bancoimagenes-staging.superkompras.com.mx';


const uploadProduct = `${Url}/Api/V1/Productos/upload-images3`;
const updateProduct = `${Url}/Api/V1/Productos/update-images`;
const removeProduct = `${Url}/Api/V1/Productos/`;
const getProducts = `${Url}/Api/V1/Productos/consulta-productos`;
const getProductsDays = `${Url}/Api/V1/Productos/get-products-7days`;
const getTotalProducts = `${Url}/Api/V1/Productos/get-total-products`;
const getProductsfecha = `${Url}/Api/V1/Productos/filtro-fecha`;
const getProductFotoRFCHasura = `${Url}/Api/V1/Productos/get-ProductRFCHasura`;
const getlistofProductFotoRFCHasura = `${Url}/Api/V1/Productos/get-list-of-ProductRFCHasura`; 
const getmultimediaContentGS1 = `${Url}/Api/V1/Productos/GetMultimediaContent`;

export {uploadProduct, removeProduct, getProducts,updateProduct,getProductsDays,getTotalProducts,getProductsfecha,getProductFotoRFCHasura,getlistofProductFotoRFCHasura, getmultimediaContentGS1}